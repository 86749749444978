export const navigation = [
  {
    text: 'Team',
    path: '/mannschaftswertung',
    icon: 'group'
  },
  {
    text: 'Einzel',
    path: '/einzelwertung',
    icon: 'user'
  },
  {
    text: 'Wettkampf',
    path: '/wettkampfwertung',
    icon: 'contentlayout'
  },
 
  
  ];
