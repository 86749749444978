


import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import DataGrid, {
    Column, Grouping,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { HubConnectionBuilder, HttpTransportType } from '@aspnet/signalr';
import './styles.css'; // Tell webpack that Button.js uses these styles
import DataSource from 'devextreme/data/data_source';

export default function Monitor_klein() {
    const { user, signOut } = useAuth();
    const [dataSource, setdataSource] = useState('')
    const [connectionStarted, setconnectionStarted] = useState(false)
    useEffect(() => {


        const hubConnection = new HubConnectionBuilder()
            .withUrl('https://signalr.csdresden.de/scoreboardHub', {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .build();

        const store = new CustomStore({

            load: () => hubConnection.invoke('GetAlleEinzelWertungen'),
            key: 'swimresultid',
        });
        const ds = new DataSource({
            store: store,
            reshapeOnPush: true,
        })
        hubConnection
            .start()
            .then(() => {
                hubConnection.on('NewResult', (data) => {

                    store.push([{ type: 'insert', data: data, index: 0 }]);
                });
                setdataSource(ds);
                setconnectionStarted(true);

            });


    }, []);
    function ChangeCell(cell) {
        return (
            <div className={cell.data.delta > 0 ? 'inc' : 'dec'}>
                <span className="arrow"></span>
                <span className="diff">{cell.data.delta.toFixed(2)}%</span>
            </div>
        );
    }
    function GroupCell(cell) {
   
        if (cell.data.items?.length >0 ){
            return <div><b>Wettkampf {cell.data.items[0]?.eventnumber}: {cell.data.items[0]?.distance} {cell.data.items[0]?.stroke}</b></div>;
        } else  if (cell.data.collapsedItems?.length >0 ){
            return <div><b>Wettkampf {cell.data.items[0]?.eventnumber}: {cell.data.items[0]?.distance} {cell.data.items[0]?.stroke}</b></div>;
        } else {
            return <div><b>Wettkampf {cell.text}</b></div>;
        }

      
    }
    return (
        <React.Fragment>
                
                {connectionStarted
                    && (<DataGrid
                        id="gridContainer"
                        dataSource={dataSource}
                        showBorders={true}
                        repaintChangesOnly={true}
                        highlightChanges={true}
                        scrolling={{mode:"standard"}}
                        paging={{enabled:false}}
                       
                    >
 <Grouping autoExpandAll={true} />
                        <Column dataField="firstname" caption={"Vorname"} />
                        <Column dataField="lastname"  caption={"Nachname"} />
                        <Column dataField="swimlevel"  caption={"Team"} />
                        <Column dataField="eventnumber"  caption={"Wettkampf"}  groupCellRender={GroupCell} groupIndex={0} sortIndex={0} sortOrder={"asc"} />
                      
                        <Column dataField="timeprint"  caption={"Zeit"}  />
                        <Column dataField="rp"  caption={"Pkt."} sortIndex={1} sortOrder={"desc"} format={{ type: "fixedPoint", precision: 2 }} />

                        <Column dataField="delta"  caption={"Verbesserung"} cellRender={ChangeCell} />


                    </DataGrid>)
                }
           
        </React.Fragment>
    );
}

