import { HomePage, TasksPage, ProfilePage, einzelwertungPage, monitor_kleinPage, manschaftswertung_uebersichtPage, wettkampfwertungPage } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
    {
        path: '/tasks',
        element: TasksPage
    },
    {
        path: '/profile',
        element: ProfilePage
    },
    {
        path: '/home',
        element: HomePage
    },
    {
        path: '/einzelwertung',
        element: einzelwertungPage
    }
    ,
    {
        path: '/mannschaftswertung',
        element: manschaftswertung_uebersichtPage
    }
    , {
        path: '/wettkampfwertung',
        element: wettkampfwertungPage
    }
    ,
   
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});
