


import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import DataGrid, {
    Column,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { HubConnectionBuilder, HttpTransportType } from '@aspnet/signalr';
import './styles.css'; // Tell webpack that Button.js uses these styles
import DataSource from 'devextreme/data/data_source';

export default function Manschaftswertunguebersicht() {
    const { user, signOut } = useAuth();
    const [dataSource, setdataSource] = useState('')
    const [pkt_mantarays, setpkt_mantarays] = useState('∑ 0')
    const [pkt_sharks, setpkt_sharks] = useState('∑ 0')
    const [pkt_c1, setpkt_c1] = useState('∑ 0')
    const [pkt_c2, setpkt_c2] = useState('∑ 0')
    const [p_mantarays, setp_mantarays] = useState('P. 0')
    const [p_sharks, setp_sharks] = useState('P. 0')
    const [p_c1, setp_c1] = useState('P. 0')
    const [p_c2, setp_c2] = useState('P. 0')
    const [connectionStarted, setconnectionStarted] = useState(false)
    useEffect(() => {


        const hubConnection = new HubConnectionBuilder()
            .withUrl('https://signalr.csdresden.de/scoreboardHub', {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
            })
            .build();


        const store = new CustomStore({

            load: () => hubConnection.invoke('GetManschaftswertung'),
            key: 'swimeventid',
        });
        const ds = new DataSource({
            store: store,
            sort: [
                { selector: 'rp', desc: true }],
            reshapeOnPush: true,
        })
        hubConnection
            .start()
            .then(() => {
                hubConnection.on('UpdateManschaftswertung', (data) => {
debugger;
                    store.push([{ type: 'update', data: data, key: data.swimeventid }]);
                });
                setdataSource(ds);
                setconnectionStarted(true);
                hubConnection.invoke('GetSummen').then((result) => {
                 
                    result.forEach(function (data2) {

                        switch (data2.teamName) {
                            case "DD MANTARAYS":
                                setpkt_mantarays('∑ ' + data2.rp)
                                setp_mantarays(data2.id)
                                break;
                            case "DD SHARKS":
                                setpkt_sharks('∑ ' + data2.rp)
                                setp_sharks(data2.id)
                                break;
                            case "C SPARTANS":
                                setpkt_c1('∑ ' + data2.rp)
                                setp_c1(data2.id)
                                break;
                            case "C WOLVERINES":
                                setpkt_c2('∑ ' + data2.rp)
                                setp_c2(data2.id)
                                break;
    
                        }
                    });
                });
                hubConnection.on('UpdateSummen', (data) => {
                    data.forEach(function (data2) {

                        switch (data2.teamName) {
                            case "DD MANTARAYS":
                                setpkt_mantarays('∑ ' + data2.rp)
                                break;
                            case "DD SHARKS":
                                setpkt_sharks('∑ ' + data2.rp)
                                break;
                            case "C SPARTANS ":
                                setpkt_c1('∑ ' + data2.rp)
                                break;
                            case "C WOLVERINES":
                                setpkt_c2('∑ ' + data2.rp)
                                break;

                        }
                    });

                });
            });

            
        const store2 = new CustomStore({

            load: () => hubConnection.invoke('GetTop5'),
            key: 'row_number_all',
            onLoaded: function (result) {
                debugger;
               


            }
        });

      store2.load();

    }, []);
    function ChangeCell(cell) {
        return (
            <div className={cell.data.delta >= 0 ? 'inc' : 'dec'}>
                <span className="arrow"></span>
                <span className="diff">{cell.data.delta.toFixed(2)}%</span>
            </div>
        );
    }
    function DataRow(rowInfo) {
        return (

            <React.Fragment>


                <tr >
                    <td rowSpan={2} style={{ verticalAlign: "middle" }}><b>{rowInfo.data.distance} {rowInfo.data.stroke}</b></td>
                    <td >{rowInfo.data.mantarayS_1_FIRSTNAME} {rowInfo.data.mantarayS_1_LASTNAME}</td>
                    <td> <div className={rowInfo.data.mantarayS_1_PB == 1 ? 'inc' : 'dec'}>
                        <span className="diff">{rowInfo.data.mantarayS_1_RP?.toFixed(2)} {rowInfo.data.mantarayS_1_PB == 1 ? '+1' : ''}</span>
                    </div></td>
                    <td >{rowInfo.data.sharkS_1_FIRSTNAME} {rowInfo.data.sharkS_1_LASTNAME}</td>
                    <td> <div className={rowInfo.data.sharkS_1_PB == 1 ? 'inc' : 'dec'}>
                        <span className="diff">{rowInfo.data.sharkS_1_RP?.toFixed(2)} {rowInfo.data.sharkS_1_PB == 1 ? '+1' : ''}</span>
                    </div></td>
                    <td>{rowInfo.data.c1_1_FIRSTNAME} {rowInfo.data.c1_1_LASTNAME}</td>
                    <td> <div className={rowInfo.data.c1_1_PB == 1 ? 'inc' : 'dec'}>
                        <span className="diff">{rowInfo.data.c1_1_RP?.toFixed(2)} {rowInfo.data.c1_1_PB == 1 ? '+1' : ''}</span>
                    </div></td>
                    <td>{rowInfo.data.c2_1_FIRSTNAME} {rowInfo.data.c2_1_LASTNAME}</td>
                    <td> <div className={rowInfo.data.c2_1_PB == 1 ? 'inc' : 'dec'}>
                        <span className="diff">{rowInfo.data.c2_1_RP?.toFixed(2)} {rowInfo.data.c2_1_PB == 1 ? '+1' : ''}</span>
                    </div></td>
                </tr>
                <tr>
                    <td>{rowInfo.data.mantarayS_2_FIRSTNAME} {rowInfo.data.mantarayS_2_LASTNAME}</td>
                    <td> <div className={rowInfo.data.mantarayS_2_PB == 1 ? 'inc' : 'dec'}>
                        <span className="diff">{rowInfo.data.mantarayS_2_RP?.toFixed(2)} {rowInfo.data.mantarayS_2_PB == 1 ? '+1' : ''}</span>
                    </div></td>
                    <td>{rowInfo.data.sharkS_2_FIRSTNAME} {rowInfo.data.sharkS_2_LASTNAME}</td>
                    <td> <div className={rowInfo.data.sharkS_2_PB == 1 ? 'inc' : 'dec'}>
                        <span className="diff">{rowInfo.data.sharkS_2_RP?.toFixed(2)} {rowInfo.data.sharkS_2_PB == 1 ? '+1' : ''}</span>
                    </div></td>
                    <td>{rowInfo.data.c1_2_FIRSTNAME} {rowInfo.data.c1_2_LASTNAME}</td>
                    <td> <div className={rowInfo.data.c1_2_PB == 1 ? 'inc' : 'dec'}>
                        <span className="diff">{rowInfo.data.c1_2_RP?.toFixed(2)} {rowInfo.data.c1_2_PB == 1 ? '+1' : ''}</span>
                    </div></td>
                    <td>{rowInfo.data.c2_2_FIRSTNAME} {rowInfo.data.c2_2_LASTNAME}</td>
                    <td> <div className={rowInfo.data.c2_2_PB == 1 ? 'inc' : 'dec'}>
                        <span className="diff">{rowInfo.data.c2_2_RP?.toFixed(2)} {rowInfo.data.c2_2_PB == 1 ? '+1' : ''}</span>
                    </div></td>
                </tr>
                <tr >
                    <td colSpan="9" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                        <hr style={{ marginTop: "0px", marginBottom: "0px" }}  ></hr>
                    </td>

                </tr>

            </React.Fragment>
        );
    }
    const rendermantaraysHeader = () => {
        return <b>{p_mantarays}. Platz<br></br>{pkt_mantarays}</b>;
    }
    const rendersharksHeader = () => {
        return <b>{p_sharks}. Platz<br></br>{pkt_sharks}</b>;
    }
    const renderc1Header = () => {
        return <b>{p_c1}. Platz<br></br>{pkt_c1}</b>;
    }
    const renderc2Header = () => {
        return <b>{p_c2}. Platz<br></br>{pkt_c2}</b>;
    }
    return (
        <React.Fragment>


            {connectionStarted
                && (<DataGrid
                    id="gridContainer"
                    dataSource={dataSource}
                    showBorders={true}
                    repaintChangesOnly={true}
                    highlightChanges={true}
                    height={"100%"}
                    dataRowRender={DataRow}
                    

                >


                    <Column caption="" width={"12%"} />
                    <Column caption="Dresden Mantarays" width={"15%"} />
                    <Column caption={pkt_mantarays} width={"7%"}  headerCellRender={rendermantaraysHeader} />
                    <Column caption="Dresden Sharks" width={"15%"} />
                    <Column caption={pkt_sharks} width={"7%"} headerCellRender={rendersharksHeader} />
                    <Column caption="Chemnitz Spartans" width={"15%"} />
                    <Column caption={pkt_c1} width={"7%"} headerCellRender={renderc1Header}/>
                    <Column caption="Chemnitz Wolverines" width={"15%"} />
                    <Column caption={pkt_c2} width={"7%"} headerCellRender={renderc2Header} />



                </DataGrid>)
            }

        </React.Fragment>
    );
}

